/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Episode from "../templates/episode"

export default function Index() {
  // const MarkdownForLatestEpisode = allMarkdownRemark.nodes[0]

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
        nodes {
          id
          html
          frontmatter {
            id
            title
            summary
            number
            resources
            enclosure_url
            guestSummary
            guestName
            # guestPhoto {
            #   childImageSharp {
            #     fluid(maxWidth: 200) {
            #       ...GatsbyImageSharpFluid_noBase64
            #     }
            #   }
            # }
            image {
              childImageSharp {
                original {
                  src
                }
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Episode
      data={{
        markdownRemark: data.allMarkdownRemark.nodes[0],
      }}
    />
  )
}
